/*******************************
		 Site Overrides
*******************************/
.ui.button {
	border-radius: 40px;
	.primary {
		color: #FCFCFC;
	}
}

.ui.secondary.button {
	&, &:focus{
		border-radius: 8px;
		background-color: white;
		color: @primaryColor;
		border: solid 1px @borderColor;
		padding: .5em 1em;
	}
}

.ui.secondary.button {
	&:hover	{
		background-color: white;
		border-color: @hoverColor;
		color: @hoverColor;
	}
	&:focus	{
		border-color: @hoverColor;
		color: @hoverColor;
	}
}

.ui.primary.button.active {
	&:hover	{
		background-color: @hoverColor;
	}
	&:focus	{
		background-color: @hoverColor;
	}
}

.ui.alternate.button {
	background: white;
    border: 1px solid @borderColor;
    color: @primaryColor;
	padding: .75571429em 1.48em; // reduce the default padding in order to account for the border.
}

.ui.secondary.button:not(.icon) .icon:not(.button):not(.dropdown){
	margin-left: 1em;
	margin-right: -1em;
}

//A tertiary button is a button that is flat and without background/border
//It can be used to replace a link when you want something that has a click event, but doesn't (directly) cause navigation.

.ui.tertiary.button {
	-webkit-transition: color .1s ease;
	transition: color .1s ease;
	border-radius: 0;
	margin: .28571429em .25em .28571429em 0;
	padding: .5em;
	-webkit-box-shadow: none;
	box-shadow: none;
	color:  @primaryColor;
	background: 0 0;
}


// Below is to set up the ButtonGroups styling
.ui.toggle.buttons .active.button, .ui.buttons .button.toggle.active, .ui.button.toggle.active {
        background-color: #1378a9 !important;
        color: #ffff;
        font-weight: 400;  
		border-radius: .625rem;
		margin-left: .313rem;      
    }
.ui.button.toggle.active:hover {
	background-color: @secondaryColor !important;
	}

.ui.buttons .button {        
    background-color: #ffff !important;
    color: #1378a9;

    border-radius: .625rem;
        
    margin-top: .313rem;
    margin-bottom: .313rem;
    margin-left: .313rem;

    &.button:last-child {
        margin-right: .313rem;
		border-radius: .313rem;
    }       
}

.ui.buttons {
	background-color: #ffff;
	border-radius: .625rem;
    width: fit-content;
}