/*******************************
        User Overrides
*******************************/
.ui.dropdown .menu a.item {
	color: @linkColor;
}

.ui.dropdown .menu.open-right {
	left: auto; 
	right: 0; //aligned with the right side of the icon, not the left so we stay on screen
}

.ui.dropdown .menu > .item.static {
	cursor: default;
}

/*******************************
unfortunately, the dropdown menu items need this important to get past a important in the semantic-ui .less
*******************************/
.ui.selection.dropdown .menu > .item {
padding: 0.65rem 1.14285714rem !important;
}