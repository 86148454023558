/*******************************
         Site Overrides
*******************************/
@media (max-width: 1490px) {
	.rt-table {
        font-size: 12px;
    }
}
.ui .table thead th {
		background-color: @sootGrey;
		border-right: 1px solid @grey;
		font-weight: 400;
		padding: 4px;
		border: none;
		color: white;
		font-size: 12px;
		text-align: center;

		a {
			color: white;
			text-decoration: underline;
		}
	}
	.ui.table thead th:not(:last-child) {
		border-right: 1px solid #999;
	}

	.table.ui td {
		border: 0.5px solid #999;
		text-align: center;
		padding: 4px;
	}