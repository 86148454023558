/*******************************
		 Site Overrides
*******************************/
@import (css) url('https://fonts.googleapis.com/css?family=Roboto:300&display=swap');
@import (css) url('https://fonts.googleapis.com/css?family=Roboto+Mono&display=swap');

a {
 color: @linkColor;
}

.link-color {
	color: @linkColor;
}

.forecasted-count-tab {
	width: 800px;
}

.forecasted-count-tab .ui.input > input {
	padding: 5px;
}

.diagram-tab img {
	width: inherit;
	height: inherit;
}

.modal-body img {
	width: inherit;
	height: inherit;
}

input.intersection-forecasted-input {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
}

.truncate {
	min-width: 0;
	text-overflow: ellipsis;
	overflow: hidden;
}

.ui.modal.confirmation {
	.header{
		color: @primaryColor;
		border-bottom: none;
	}

	.actions{
		border-top: none;
	}
}

.monospace {
	font-family: 'Roboto Mono', monospace;
	tab-size: 4;
}

.astrick {
	color: #FF4B3E;
	font-size: 14px;
}

.required-message {
	padding-top: 10px;
}

.create-report-required-message {
	padding-top: 10px;
	font-size: 12px;
}

/*******************************
	React-datepicker styling
*******************************/
//Allow the datepicker input to fill the width of its container
.react-datepicker-wrapper {
	width: 100%;

	.react-datepicker__input-container {
		width: 100%
	}
}


/*******************************
	Flex container classes
*******************************/
.flex-container {
	display: flex;

	&.center {
		justify-content: center;
		align-items: center;
	}

	&.end {
		justify-content: flex-end;
		align-items: flex-end;
	}
}

/*******************************
	Sidebar pane widths
*******************************/
.sidebar-medium-width {
	min-width: @PaneWidthMedium
}

.error-text {
	color: @errorTextColor
}

// Reset property which is messing up map icons in geometry search
.map {
	*, *:after, *:before {
		box-sizing: content-box;
	}
}
	
//clearable wraps an input and adds a button. We want to style the input and the button so the button looks
//like it's contained in the input, to match how clearable dropdowns look.
//We accomplish this with absolute positioning, so the button appears over and inside the border of the input control
// (that was the only way to preserve the focus border of the input).
.ui.input.clearable-wrapper > button {
	background: white;
	color: rgba(0, 0, 0, 0.87);
	border: none;
	font-size: 0.85714286em; //to match the dropdown X
	position: absolute;
	top: 1px;
	right: 1px;
	bottom: 1px;
	width: 34px;
}

.ui.input.clearable-wrapper > *:first-child,
.ui.form .fields .field .ui.input.clearable-wrapper input, 
.ui.form .field .ui.input.clearable-wrapper input {
	width: 100%;
}

.ui.input.clearable-wrapper  .ui.icon.input > .icon {
	right: 34px; //to the left of the clear button
}
