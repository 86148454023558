@import "../../semantic-ui/site/globals/site.variables";

.module-icon-container {
	width: 9.5em;
	height: 10em;
	background: @white;
	border-radius: 10px;
	margin: 1em;
	cursor: pointer;

	&.disabled {
		cursor: default;
		opacity: 0.6;
	}

	&.active-ms2-module {
		border: 5px solid @primaryColor;
	}

	.module-icon-image-container {
		margin: 2px;
		height: 6em;
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
		overflow: hidden;

		.module-icon-image {
			width: 100%;
			height: 100%;
		}
	}

	.module-icon-label-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		.module-icon-long-name {
			color: @sootGrey;
			font-size: 10px;
		}

		.module-icon-acronym {
			color: @primaryColor;
			font-size: 20px;
		}
	}
}
