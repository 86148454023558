/*******************************
         Site Overrides
*******************************/
i.icon.fa,
i.icon.fas {
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
}

i.icon.far {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400;
}

i.icon.centered {
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.ui.labeled.icon.buttons > .button > .icon, .ui.labeled.icon.button > .icon {
    position: absolute;
    height: 100%;
    line-height: 1;
    border-radius: 0px;
    border-top-left-radius: inherit;
    border-bottom-left-radius: inherit;
    text-align: center;
    margin: 0em;
    width: 2.57142857em;
    background-color: transparent;
    color: '';
    box-shadow: -1px 0px 0px 0px transparent inset;
}