@import "../../semantic-ui/site/globals/site.variables";

.module-list-container {
	margin-bottom: 3em;

	.module-list-title {
		color: @sootGrey;
		font-size: 18px;
	}

	.modules-container {
		display: flex;
		flex-wrap: wrap;
	}
}
