@import "../../semantic-ui/site/globals/site.variables";

.portal-main-container {
	position: relative;
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	padding: 20px;

	.portal-login-button,
	.portal-login-button:hover,
	.portal-login-button:active {
		background-color: @primaryColor;
		color: white;
		border-radius: 6px;
		padding: 10px 55px;
		margin-bottom: 20px;
		align-self: center;
	}

	@media only screen and (max-width: 767px) {
		.portal-login-button {
			width: 90%;
		}
	}
}
