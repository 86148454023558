/*******************************
		 Site Overrides
*******************************/

.ui.segment.no-padding {
	padding: 0;
	margin: 0;
}

.ui.segment.clearing.no-padding{
	margin-bottom: 1rem; //margin required for the float clear
}