/*******************************
         Site Overrides
*******************************/
.modal-body() {
	background-color: @modalGrey;
	padding: 20px;
}
.modal-header() {
	color: @primaryColor;
	font-size: 2.5em;
	line-height: 2.5em;
	font-weight: normal;
}

.ui.modal {
	padding: 2em 2em 0 2em;

	&.import-export-confirm{
    	width: 40%;
    	background-color: #e8e8e8;
	}

	.ui.header {
		color: @primaryColor;
		border-bottom: none;
	}

	&.width-auto {
		width: auto;

		.container {
			width: auto;
		}
	}

	.actions{
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
		background: none;
		border-top: none;

		&.single-button {
			justify-content: flex-end;
		}
	}

	.modal-header {
		.modal-header();
	}
}

.ui.modal.modal-body {
	.modal-body();
}

.info-title {
	color: @secondaryColor;
}

.info-value {
	color: @charcoal;
	font-size: 18px;
}
