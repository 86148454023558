/*******************************
        User Overrides
*******************************/
.tab-style-one > .ui.attached.tabular.menu,
.tab-style-two > .ui.attached.tabular.menu {
    background: @white;
    border-bottom: unset;
    width: fit-content;
    
    .item {
        border: unset;
        background: inherit;
        color: @primaryColor;

        &.active {
            background: @primaryColor;
            color: @white;
            font-weight: normal;
        }
    }
}

// Tab Header non common
.tab-style-one > .ui.attached.tabular.menu {
    border-radius: 10px ;
    padding: 5px ;
    margin: 0 auto;

    .item {
        padding: 10px 50px;
    }

    .item.active {
        border-radius: 10px !important; // Necessary to override semantic ui
    }   
}

.tab-style-two > .ui.attached.tabular.menu {
    border-radius: unset;
    width: fit-content;
    
    .item.active {
        border-radius: unset !important; // Necessary to override semantic ui
    }

    .item:not(:first-child) {
        border-left: 1px solid @tertiaryGrey;
    }  
}

// Tab Body
.tab-style-one > .ui.bottom.attached.segment, 
.tab-style-two > .ui.bottom.attached.segment {
    background: inherit;
    margin-top: 3em;
    border: unset !important; // Necessary to override semantic ui
}

        