@import "../../semantic-ui/site/globals/site.variables";

footer {
	padding-left: 2em;
	padding-right: 2em;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	background: @primaryColor;
	color: @white;

	> div {
		display: flex;
		justify-content: center;
		align-items: center;
		white-space: nowrap;

		> * + * {
			&:before {
				content: "|";
				margin: 1em;
			}
		}
	}

	> div + div {
		&:before {
			content: "|";
			margin-left: 1.5em;
			margin-right: 1.5em;
		}
	}

	a,
	a:visited,
	a:active,
	a:hover,
	a:focus {
		color: @white;
		text-decoration: underline;
	}
}
