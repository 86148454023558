@import "../../semantic-ui/site/globals/site.variables";

.ms2-header {
	display: flex;
	align-items: center; //center vertically
	background-color: @primaryColor;
	color: white;
	margin-bottom: 0;
	height: 60px;
	padding-left: 0.5em;
	padding-right: 1em;

	&.with-drawer-toggle {
		padding-left: 2em;
	}

	.client-logo-container {
		align-self: flex-start;
	}

	.ms2-drawer-toggle {
		margin-left: -28px;
		margin-right: 15px;
		cursor: pointer;

		&:focus {
			outline: none;
		}
	}

	.ms2-header-content {
		flex-grow: 1;
		display: flex;
		max-height: 100%;
		align-items: center;
	}

	.ms2-module-name {
		margin-left: 1em;
		margin-bottom: 0;
		color: @lightBlue;
		font-size: 18px;
		white-space: nowrap;
	}

	@media only screen and (max-width: 767px) {
		.ms2-module-name {
			display: none;
		}
	}

	.MS2-logo {
		margin-left: auto; //right align
		margin-right: 1.5em;

		img {
			max-width: 80px;
			vertical-align: middle;
		}

		padding-left: 2em;
	}

	.ui.dropdown .menu {
		padding-bottom: 1em;

		.item {
			font-size: 1.25rem;
			line-height: 2rem;
			padding-left: 1.5em !important;
			padding-right: 1.5em !important;

			&.logged-in-as {
				background-color: #999999;
				color: white;
				border-radius: 10px 10px 0 0;
				line-height: 1.75rem;
				margin: -1px -1px -1px 0; //need to be flush with the container's border.
			}
		}
	}
}
