@import "../../semantic-ui/site/globals/site.variables";

.client-logo-container {
	width: 105px;
	height: 54px;
	display: flex;
	background-color: @white;
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;
	justify-content: center;
	align-items: center;
	color: darkslategray;
	cursor: pointer;

	.client-logo {
		width: 96px;
		height: 50px;
		object-fit: contain;
	}
}
