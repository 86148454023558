.ms2-layout {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	background: #f5f5f5;
}

.ui[class*="very wide"].left.sidebar {
	width: 50%;
}

footer {
	font-size: 16px;
	padding: 4px 0;
}

@media print {
	footer {
		border-top: 1px solid #000;
		padding-top: 10pt;
		background: #fff;
	}

	footer,
	footer a {
		color: #000;
		display: inline;
		font-size: 11pt;
		text-align: center;
		text-decoration: none;
	}

	footer > div {
		margin: 4pt 0;
	}

	footer > div > span {
		padding-right: 8pt;
	}

	footer > div:nth-child(3) > a {
		display: none;
	}
}
