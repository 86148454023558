/*******************************
        Site Overrides
*******************************/

.ui.compact.icon.message{
	width: auto;
}

.ui.message.global-float {
	position: absolute;
	top: 0;
	left: 50%;
	transform: translate(-50%, 0);
	border-radius: 10px;
}

.ui.message > .close.icon + .content{
	padding-right: 1em;
}

.ui.message.success {
	border-left: solid 4px @successColor;
}

.ui.message > button.close.icon {
	top: 1em;
	right: 0.25em;
}

.ui.message.error {
	border-left: solid 4px @errorColor;
}