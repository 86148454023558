.module-navigation-drawer-container {
	width: 100%;
	min-height: 100%;
	background: #f5f5f5;
	display: flex;
	flex-direction: column;
	overflow: hidden;

	.ms2-drawer-toggle {
		margin-top: 15px;
	}

	.modules-container {
		flex-grow: 1;
		overflow-y: auto;
	}

	.ui.segment {
		margin: 0;
	}
}
